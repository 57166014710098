.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
}

.sidebar {
  /* overflow: auto; */
  /* height: calc (100vh - 500px); */
  /* height: 100%; */
  /* height: 500px; */
  height: 100vh;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  overflow: hidden;
}

.menu {
  /* height: calc(100vh - 600px);
  background-color: red; */
  padding: 0 !important;
  overflow: hidden;
}

@media screen and (min-width: 576px) {
  .headerContainer {
    padding: 0 2rem;
  }
}
